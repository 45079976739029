<template>
  <v-container>
    <h1 class="ma-4 text-center">{{ $t("farmOrderItems") }}</h1>
    <ul class="tab-list">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        class="tab-item"
        :class="{ active: tabId === index }"
      >
        <button @click="statusHandler(tab, index)">{{ $t(`${tab}`) }}</button>
      </li>
    </ul>

    <MstDateFilter
      v-model="selectedDate"
      :date-picker-dates="datePickerDates"
      :max-date="maxDate"
      :status="status"
      @change="dateHandler"
      @handle-prev-month="getPrevMonthData"
      @handle-next-month="getNextMonthData"
    />

    <div>
      <div class="ma-2 mt-0" style="overflow-x: auto">
        <div v-if="loadingStatus">{{ $t("loading") }}</div>
        <div v-if="status === 'unconfirmed'">
          <div :key="index" v-for="(item, index) in items">
            <UnConfirmedCard @openConfirmModal="openModal(item.id, 'confirm')" @openCancelModal="openModal(item.id, 'cancel')" :id="item.id" :genusName="item.genus_name"
              :speciesName="item.flower_specie" :stemSize="item.stem_size" :lotCount="item.lot_count" :harvestMinCount="item.min_count" :price="item.unit_price"
              :totalPrice="item.subtotal_harvest_price_without_tax" :totalCount="item.subtotal_count" :shopName="item.shop_name" :warehouse="item.warehouse" :status="item.status"
              :request_answer_deadline="item.request_answer_deadline" :note="item.note" />
          </div>
          <div class="modal-mask" v-if="showConfirmModal || showCancelModal">
            <div class="modal-wrapper">
              <div class="modal-container">
                <div class="d-flex justify-center align-center">
                  <img :src="require('@/assets/product.svg')" width="56" height="56"/>
                </div>
                <div class="text-center my-4">
                  <span v-if="showConfirmModal" class="font-weight-bold">{{ $t('request_approval') }}</span>
                  <span v-else class="font-weight-bold">{{ $t('request_reject') }}</span>
                </div>
                <div class="text-center">
                  <span>{{ $t('cannot_be_undone_later') }}</span>
                </div>
                <div v-if="showConfirmModal" class="d-flex justify-space-between my-4">
                  <button class="btn-cancel" @click="showConfirmModal = false">{{ $t('cancel') }}</button>
                  <button class="btn-approve" @click="accept('forward')">{{ $t('approve') }}</button>
                </div>
                <div v-else class="d-flex justify-space-between my-4">
                  <button class="btn-cancel" @click="showCancelModal = false">{{ $t('cancel') }}</button>
                  <button class="btn-reject" @click="reject">{{ $t('reject_order') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="status === 'confirmed'">
          <div :key="index" v-for="(item, index) in groupedItems">
            <ConfirmedCard :genusName="item.genus_name" :speciesName="item.flower_specie" :stemSize="item.stem_size" :totalPrice="item.subtotal_harvest_price_without_tax"
            :totalCount="item.subtotal_count" :transactions="groupedTransactions[item.key]" @openShipModal="openShipModal(groupedTransactions[item.flower_id])"
            :note="item.note" :itemKey="item.key" />
          </div>
          <div class="modal-mask" v-if="showShippedModal">
            <div class="modal-wrapper">
              <div class="modal-container">
                <div class="d-flex justify-center align-center">
                  <CarIcon size="56" :color="'#173169'" />
                </div>
                <div class="text-center my-4">
                  <span class="font-weight-bold">{{ $t('mark_as_shipped') }}</span>
                </div>
                <div class="text-center">
                  <span>{{ $t('cannot_be_undone_later') }}</span>
                </div>
                <div class="d-flex justify-center my-4">
                  <button class="btn-cancel" @click="showShippedModal = false">{{ $t('cancel') }}</button>
                  <!-- <button class="btn-shipped" @click="goShip">{{ $t('mark_shipped') }}</button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div :key="index" v-for="(item, index) in items">
            <ShippedCard :id="item.id" :speciesName="item.flower_specie" :stemSize="item.stem_size" :lotCount="item.lot_count" :harvestMinCount="item.min_count"
            :price="item.unit_price" :totalPrice="item.subtotal_harvest_price_without_tax" :totalCount="item.subtotal_count" :shopName="item.shop_name"
            :genusName="item.genus_name" :status="item.status" :warehouse="item.warehouse" :note="item.note" />
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import ConfirmedCard from "@/components/farm/orderItems/ConfirmedCard.vue";
import UnConfirmedCard from "@/components/farm/orderItems/UnConfirmedCard.vue";
import ShippedCard from "@/components/farm/orderItems/ShippedCard.vue";
import MstDateFilter from "@/components/farm/orderItems/MstDateFilter.vue";
import { formatDate } from "@/plugins/i18n";
import CarIcon from "@/components/icons/CarIcon.vue";

export function toDateKey(date) {
  return formatDate(date, "yyyy-mm-dd");
}

export function toDateTime(date) {
  return formatDate(date, "yyyy-mm-dd hh:MM");
}

export default {
  components: { ConfirmedCard, UnConfirmedCard, ShippedCard, CarIcon, MstDateFilter },
  data() {
    return {
      mobile: this.$vuetify.breakpoint.xs,
      status: "request",
      groupBy: "order_item",
      headers: [
        { text: this.$t("id"), value: "id", width: "60px" },
        { text: this.$t("min_count"), value: "min_count", width: "60px" },
        { text: this.$t("set_count"), value: "set_count", width: "60px" },
        { text: this.$t("unit_price"), value: "unit_price", width: "40px" },
        { text: this.$t("shop_name"), value: "shop_name", width: "80px" }
      ],
      items: [],
      selectedDate: dayjs().toDate(),
      selectedItems: [],
      currentDate: "",
      maxDate: dayjs().add(1, "year").toDate(),
      tabs: ["request", "unshipped", "shipped"],
      tabId: 0,
      showShippedModal: false,
      showConfirmModal: false,
      showCancelModal: false,
      loadingStatus: false,
      datePickerDates: [],
    };
  },
  computed: {
    store() {
      return this.$store.getters["orderItems/getState"];
    },
    isGroupByShop() {
      return this.groupBy === "shop";
    },
    hashedItems() {
      const hashedItems = {};
      this.items.forEach(item => {
        const key = `${item.flower.id}_${item.stem_size}_${item.harvest_id}`;
        if (hashedItems[key]) {
          hashedItems[key].subtotal_count += item.subtotal_count;
          hashedItems[key].subtotal_harvest_price_without_tax += item.subtotal_harvest_price_without_tax;
        } else {
          hashedItems[key] = {
            harvest_id: item.harvest_id,
            subtotal_count: item.subtotal_count,
            subtotal_harvest_price_without_tax: item.subtotal_harvest_price_without_tax,
            flower_specie: item.flower_specie,
            genus_name: item.genus_name,
            stem_size: item.stem_size,
            note: item.note,
            key: `${item.flower.id}_${item.stem_size}_${item.harvest_id}`
          };
        }
      });
      return hashedItems;
    },
    groupedItems() {
      const groupedItems = [];
      Object.keys(this.hashedItems).forEach(arr => {
        groupedItems.push(this.hashedItems[arr]);
      });
      return groupedItems;
    },
    groupedTransactions() {
      const groupedTransactions = {};
      this.items.forEach(item => {
        const key = `${item.flower.id}_${item.stem_size}_${item.harvest_id}`;
        if (Object.keys(groupedTransactions).includes(String(key))) {
          if (Object.keys(groupedTransactions[key]).includes(item.warehouse)) {
            groupedTransactions[key][item.warehouse].total_count += item.subtotal_count;
            groupedTransactions[key][item.warehouse].order_items.push(item);
          } else {
            groupedTransactions[key][item.warehouse] = {};
            groupedTransactions[key][item.warehouse].total_count = item.subtotal_count;
            groupedTransactions[key][item.warehouse].order_items = [item];
          }
        } else {
          groupedTransactions[key] = {};
          groupedTransactions[key][item.warehouse] = {};
          groupedTransactions[key][item.warehouse].total_count = item.subtotal_count;
          groupedTransactions[key][item.warehouse].order_items = [item];
        }
      });
      return groupedTransactions;
    },
    allowWdayList() {
      return this.$store.getters.workingWdays;
    },
  },
  watch: {
    selectedDate() {
      return this.selectedDate;
    }
  },
  async created() {
    const { query } = this.$route;
    let date = dayjs().toDate();
    if (query.date) date = dayjs(query.date).toDate();

    switch (query.status) {
      case "request":
        this.status = "unconfirmed";
        this.tabId = 0;
        break;
      case "shipped":
        this.status = "shipped";
        this.tabId = 2;
        break;
      default:
        this.status = "confirmed";
        this.tabId = 1;
    }

    const nearest_date = this.$store.state.orderItems.date_picker_dates
      .filter(_date => new Date(_date) > date)
      .sort((a, b) => new Date(a) - new Date(b))
      .shift();
    if (nearest_date) {
      await this.dateHandler(new Date(nearest_date));
    } else {
      await this.dateHandler(date);
    }
  },
  methods: {
    async fetchData(date) {
      this.loadingStatus = true;
      await this.$store.dispatch("orderItems/getOrderItems", {
        date,
        status: this.status,
      }).then(() => {
        this.createTableData();
      });
      this.loadingStatus = false;
    },
    async statusHandler(status, index) {
      this.items = [];
      this.tabId = index;
      switch (status) {
        case "request":
          this.status = "unconfirmed";
          break;
        case "unshipped":
          this.status = "confirmed";
          break;
        default:
          this.status = "shipped";
      }
      await this.dateHandler(this.selectedDate);
    },
    async dateHandler(d) {
      this.currentDate = d;
      this.selectedDate = d;
      await this.fetchData(toDateKey(d));
    },
    async createTableData() {
      const { orderItems } = this.$store.state;

      this.items = [];
      const targetOrderItems = [];
      this.datePickerDates = orderItems.date_picker_dates;

      if (this.status === "shipped") {
        orderItems.listArr.unloaded.map(id => targetOrderItems.push(id));
        orderItems.listArr.picked.map(id => targetOrderItems.push(id));
        orderItems.listArr.delivered.map(id => targetOrderItems.push(id));
        orderItems.listArr.shipped.map(id => targetOrderItems.push(id));
      } else if (this.status === "unconfirmed") {
        orderItems.listArr.unconfirmed.map(id => targetOrderItems.push(id));
        if (orderItems.listArr.canceled) orderItems.listArr.canceled.map(id => targetOrderItems.push(id));
        if (orderItems.listArr.rejected_request) orderItems.listArr.rejected_request.map(id => targetOrderItems.push(id));
      } else if (this.status === "confirmed") {
        orderItems.listArr.confirmed.map(id => targetOrderItems.push(id));
      }

      targetOrderItems.forEach(id => {
        let storeOrderItems = this.store.list[this.status];
        if (this.status === "shipped") {
          storeOrderItems = {
            ...storeOrderItems,
            ...this.store.list.unloaded,
            ...this.store.list.picked,
            ...this.store.list.delivered,
            ...this.store.list.shipped
          };
        } else if (this.status === "unconfirmed" && (orderItems.listArr.canceled || orderItems.listArr.rejected_request)) {
          storeOrderItems = {
            ...storeOrderItems,
            ...this.store.list.canceled,
            ...this.store.list.rejected_request
          };
        } else if (this.status === "confirmed") {
          storeOrderItems = {
            ...storeOrderItems,
            ...this.store.list.confirmed
          };
        }

        const orderItem = storeOrderItems[id];

        if (
          this.$date.compareDates(this.selectedDate, new Date(orderItem.flower_harvest.harvest_date))
        ) {
          if (this.isGroupByShop) {
            this.headers = [
              { text: this.$t("id"), value: "id", width: "60px" },
              { text: this.$t("min_count"), value: "min_count", width: "60px" },
              { text: this.$t("set_count"), value: "set_count", width: "60px" },
              { text: this.$t("unit_price"), value: "unit_price", width: "40px" },
              { text: this.$t("flower_specie"), value: "flower_specie", width: "80px" },
            ];
          } else if (this.groupBy === "order_item" && this.status === "confirmed") {
            this.headers = [
              { text: this.$t("flower_specie"), value: "flower_specie", width: "120px" },
              { text: this.$t("stem_size"), value: "stem_size", width: "80px" },
              { text: this.$t("subtotal_count"), value: "subtotal_count", width: "80px" },
              { text: this.$t("note"), value: "note", width: "500px" },
            ];
          } else {
            this.headers = [
              { text: this.$t("id"), value: "id", width: "60px" },
              { text: this.$t("min_count"), value: "min_count", width: "60px" },
              { text: this.$t("set_count"), value: "set_count", width: "60px" },
              { text: this.$t("unit_price"), value: "unit_price", width: "40px" },
              { text: this.$t("shop_name"), value: "shop_name", width: "80px" }
            ];
          }
          this.items.push({
            ...orderItem,
            shop_name: orderItem.shop.name,
            warehouse: orderItem.shop.warehouse,
            genus_name: orderItem.flower.flower_specie.flower_genus.name,
            flower_specie: orderItem.flower.flower_specie.name,
            unit_price: orderItem.harvest_price,
            min_count: orderItem.harvest_min_count,
            set_count: orderItem.lot_count,
            harvest_id: orderItem.flower_harvest.id,
            subtotal_count: orderItem.subtotal_count,
            note: orderItem.flower_harvest.note,
            stem_size: `${orderItem.flower_harvest.stem_size || 0}cm`,
            group_by_id: this.isGroupByShop ? orderItem.shop.id : orderItem.flower_harvest.id,
            order_date: toDateTime(orderItem.order.order_date),
            request_answer_deadline: toDateTime(orderItem.request_answer_deadline)
          });

          this.items.sort((a, b) => a.harvest_id - b.harvest_id);
        }
      });
    },
    clickHandler(item) {
      this.$router.push({
        path: `/farm/order_items/${item.id}`,
        query: { date: this.$date.YYYYMMDD(new Date(this.currentDate)), status: this.status }
      });
    },
    accept(side) {
      const payload = {
        side,
        selectedItems: this.selectedItems,
        status: this.status
      };
      this.$store.dispatch("orderItems/postOrderItems", payload).then(res => {
        this.showConfirmModal = false;
        if (res) {
          this.$store
            .dispatch("orderItems/getOrderItems", {
              date: this.$date.YYYYMMDD(new Date(this.currentDate)),
              status: this.status
            })
            .then(this.createTableData);
          this.showConfirmModal = false;
        }
      });
    },
    reject() {
      const payload = {
        order_item_ids: this.selectedItems
      };
      this.$store.dispatch("orderItems/rejectRequest", payload).then(res => {
        this.showCancelModal = false;
        if (res) {
          this.$store
            .dispatch("orderItems/getOrderItems", {
              date: this.$date.YYYYMMDD(new Date(this.currentDate)),
              status: this.status
            })
            .then(this.createTableData);
        }
      });
    },
    openShipModal(items) {
      this.selectedItems = [];
      this.showShippedModal = true;
      items.forEach(item => {
        this.selectedItems.push(item.id);
      });
    },
    openModal(id, modalName) {
      this.selectedItems = [];
      if (modalName === "confirm") {
        this.showConfirmModal = true;
      } else {
        this.showCancelModal = true;
      }
      this.selectedItems.push(id);
    },
    async goShip() {
      // const items = this.selectedItems;
      // await this.$store.dispatch("driverInformation/editStatus", {
      //   transitionType: "shipped",
      //   items,
      // });
      // this.createTableData();
      this.showShippedModal = false;
    },
    async getNextMonthData() {
      this.currentDate.setMonth(this.currentDate.getMonth() + 1);
      await this.fetchData(toDateKey(this.currentDate));
    },
    async getPrevMonthData() {
      this.currentDate.setMonth(this.currentDate.getMonth() + -1);
      await this.fetchData(toDateKey(this.currentDate));
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  max-width: variables.$container-max-width;
}
.tab-item button {
  border: none;
  background: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}

.tab-list {
  display: flex;
  list-style: none;
  padding: 0;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
}

.tab-list li {
  margin-right: 10px;
  font-weight: 700;
  color: gray;
  font-weight: 400;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
}

.tab-list li.active {
  border-bottom: 2px solid #173169;
  color: #173169;
}

.rounded-8 {
  border-radius: 8px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 344px;
  margin: 0px auto;
  padding: 20px 45px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.btn-cancel {
  border-radius: 4px;
  background-color: white !important;
  color: #173169 !important;
  border: 1px solid #173169 !important;
  width: 120px;
  height: 40px;
  font-weight: 400 !important;
}

.btn-shipped {
  border-radius: 4px;
  color: white !important;
  background-color: #173169 !important;
  font-weight: 400 !important;
  width: 120px;
  height: 40px;
}

.btn-approve {
  border-radius: 4px;
  color: white !important;
  background-color: #67A16D !important;
  font-weight: 400 !important;
  height: 40px;
  width: 120px;
}

.btn-reject {
  border-radius: 4px;
  color: white !important;
  background-color: #C75757 !important;
  font-weight: 400 !important;
  height: 40px;
  width: 120px;
}

.text-overflow-dot {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.no-wrap {
  white-space: nowrap;
}
</style>
